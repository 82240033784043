import React, { FC } from 'react';
import PromoFormComponent from '@promo-platform/promo-form';

import Button from 'components/elements/Button';
import Typography from 'components/elements/Typography';
import BaseSection from 'components/helpers/BaseSection';
import DynamicImage from 'components/helpers/DynamicImage';

import { IPropsImageWithForm } from './models';

import './ImageWithForm.scss';

const ImageWithForm: FC<IPropsImageWithForm> = (props) => {
  const { data: [data] } = props;
  const { title, text, ctaButton, sectionpromoformid, sectionpromoFormUrl, image, sectionSettings, promoformcheckbox } = data;
  
  return (
    <BaseSection data={sectionSettings}>
      <div data-testid="ImageWithForm" className="image-with-form">
        <div className="image-with-form__content">
          <div className="image-with-form__right">
            {image ? (              
                <DynamicImage image={image} isLazyLoading={false} />
            ) : null}
          </div>
          <div className="image-with-form__left">
          <Typography data={title} customClass="image-with-form__title" />
          <Typography data={text} customClass="image-with-form__text" />
          
          {ctaButton?.length ? (
            <div className="image-with-form__cta">
              <Button data={ctaButton} />
            </div>
          ) : null}
          {promoformcheckbox && promoformcheckbox === '1' && sectionpromoformid?.length && sectionpromoFormUrl?.length ? (
            <PromoFormComponent id={sectionpromoformid} url={sectionpromoFormUrl} />
          ) : null }    
                
          </div>
        </div>
      </div>
    </BaseSection>
  );
};

export default ImageWithForm;
